import Lenis from 'lenis'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * Represents the Base class.
 * @class
 */
class Base {
	constructor() {
		this.lenis = new Lenis()
		this.referencesList = document.querySelector('.references-list')
		this.scrollToButtons = [...document.querySelectorAll('[data-scroll-to]')]
		this.backToTopButton = document.querySelector('.backToTop')
		this.menuButton = document.querySelector('.hamburger')
		this.sections = [...document.querySelectorAll('section')]
		this.mq = window.matchMedia('(min-width: 1024px)')
		this.isMainSite = document.querySelector('.landing')

		this.scrollToTarget = this.scrollToTarget.bind(this)
	}

	/**
	 * Initializes the Base class.
	 */
	init() {
		this.initScrollTriggers()
		this.addEvents()
	}

	/**
	 * Adds event listeners.
	 */
	addEvents() {
		this.lenis.on('scroll', ScrollTrigger.update)
		this.scrollToButtons.forEach((button) => {
			button.addEventListener('click', this.scrollToTarget)
		})
		window.addEventListener('load', () => {
			document.body.classList.remove('preload')
		})
		window.addEventListener('hashchange', () => {
			console.log('hashchange', location.hash)
		})
	}

	/**
	 * Initializes the scroll triggers.
	 */
	initScrollTriggers() {
		gsap.ticker.add((time) => {
			this.lenis.raf(time * 1000)
		})

		gsap.ticker.lagSmoothing(0)

		this.initBackToTopButtonTrigger()

		if (this.isMainSite) {
			this.initSectionObserverTrigger()
			this.initServicesScrollTrigger()
		}
	}

	/**
	 * Initializes the services scroll trigger.
	 */
	initServicesScrollTrigger() {
		gsap.utils.toArray('.fade-up, .fade-elastic').forEach((element, index) => {
			if (element.classList.contains('fade-up')) {
				let startY = 50
				let endY = 0

				if (this.mq.matches && element.classList.contains('translateY')) {
					startY = 100
					endY = 50
				}

				gsap.fromTo(
					element,
					{ opacity: 0, y: startY },
					{
						opacity: 1,
						y: endY,
						duration: 1,
						delay: 0.2,
						ease: 'power2.out',
						scrollTrigger: {
							trigger: element,
							start: this.mq.matches ? (index % 2 === 0 ? 'top 80%' : 'top 70%') : 'top 80%',
							end: 'top 20%'
						}
					}
				)
			}

			if (element.classList.contains('fade-elastic')) {
				gsap.fromTo(
					element,
					{ opacity: 0, scale: 0.9 },
					{
						opacity: 1,
						scale: 1,
						duration: 1,
						delay: 0.2,
						ease: 'elastic.out(1, 0.3)',
						scrollTrigger: {
							trigger: element,
							start: 'top 80%',
							end: 'top 20%'
						}
					}
				)
			}
		})
	}

	/**
	 * Initializes the back to top button trigger.
	 */
	initBackToTopButtonTrigger() {
		ScrollTrigger.create({
			start: 0,
			onUpdate: (self) => {
				if (self.scroll() > 300) {
					this.backToTopButton.classList.add('show')
				} else {
					this.backToTopButton.classList.remove('show')
				}
			}
		})
	}

	/**
	 * Initializes the section observer trigger.
	 */
	initSectionObserverTrigger() {
		this.sections.forEach((section) => {
			ScrollTrigger.create({
				trigger: section,
				start: 'top 20%',
				end: 'bottom top',
				onEnter: () => this.handleActiveElements(section.id),
				onEnterBack: () => this.handleActiveElements(section.id)
			})
		})
	}

	/**
	 * Scrolls to the target element.
	 * @param {Event} event - The click event.
	 */
	scrollToTarget(event) {
		event.preventDefault()
		const target = event.target
		const targetId = target.getAttribute('data-scroll-to')
		const targetElement = document.querySelector(`#${targetId}`)

		this.lenis.scrollTo(targetElement || 0, {
			duration: 1,
			easing: (x) => {
				return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
			},
			onComplete: () => {
				this.menuButton.classList.contains('active') && this.menuButton.click()
			}
		})
	}

	/**
	 * Handles the active elements.
	 * @param {string} id - The section id.
	 */
	handleActiveElements(id) {
		document.querySelector('.navbar-listItem.active')?.classList.remove('active')
		document.querySelector(`.navbar-listItem[data-section="${id}"]`)?.classList.add('active')
	}
}

const base = new Base()
base.init()
