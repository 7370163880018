/**
 * Focus trap
 * Set and keep focus between given elements
 * @module FocusTrap
 */

export default class FocusTrap {
	/**
	 * Set default variables as properties
	 * @param {object} options Options
	 * @param {HTMLElement} options.parentContainer - Parent container of the first and last focusable elements
	 * @param {HTMLElement} options.firstFocusableElement - First DOM node to set focus on
	 * @param {HTMLElement} options.lastFocusableElement - Last DOM node to be focused before going back to the first one
	 * @param {HTMLElement} options.firstActiveElementToFocus - Element to focus first if different from the first focusable element
	 */
	constructor({
		parentContainer,
		firstFocusableElement,
		lastFocusableElement,
		firstActiveElementToFocus = false
	}) {
		this.parentContainer = parentContainer
		this.firstFocusableElement = firstFocusableElement

		this.focusableElements =
			'button:not([disabled]), [href]:not(use), input, select, textarea, [tabindex]:not([tabindex="-1"])'
		this.focusableContent = [...this.parentContainer.querySelectorAll(this.focusableElements)]

		this.lastFocusableElement = lastFocusableElement
		this.firstActiveElementToFocus = firstActiveElementToFocus

		this.updateFocusableContent = this.updateFocusableContent.bind(this)
	}

	/**
	 * Initialize the module
	 */
	init() {
		this.addEvents()
		if (this.firstActiveElementToFocus instanceof HTMLElement) {
			this.firstActiveElementToFocus.focus()
		} else {
			this.firstFocusableElement.focus()
		}
	}

	/**
	 * Add event
	 */
	addEvents() {
		document.addEventListener('keydown', this.updateFocusableContent)
	}

	/**
	 * Update focusable elements based on potential disabled elements
	 * @param {Event} e Event data
	 */
	updateFocusableContent(e) {
		this.focusableContent = [...this.parentContainer.querySelectorAll(this.focusableElements)]
		const lastFocusableElementFromArray = this.focusableContent[this.focusableContent.length - 1]
		this.lastFocusableElementOriginalValue = this.lastFocusableElement

		if (!this.focusableContent.includes(this.lastFocusableElement)) {
			this.lastFocusableElement = lastFocusableElementFromArray
		}

		this.setFocus(e)
	}

	/**
	 * Set and keep focus on element
	 * @param {Event} e Event data
	 */
	setFocus(e) {
		if (e.key === 'Tab' || e.keyCode === 9) {
			this.isTabPressed(e)
		}

		if (this.lastFocusableElementOriginalValue) {
			this.lastFocusableElement = this.lastFocusableElementOriginalValue
		}
	}

	/**
	 * Checks if the Tab key is pressed and calls the appropriate focus method.
	 * @param {Event} e - The keydown event object.
	 */
	isTabPressed(e) {
		if (e.shiftKey) {
			this.focusBackward(e)
		} else {
			this.focusForward(e)
		}
	}

	/**
	 * Moves the focus backward to the last focusable element when the first focusable element is active.
	 * @param {Event} e - The event object.
	 */
	focusBackward(e) {
		if (document.activeElement === this.firstFocusableElement) {
			e.preventDefault()
			this.lastFocusableElement.focus()
		}
	}

	/**
	 * Focus forward
	 * @param {Event} e - The event object.
	 */
	focusForward(e) {
		if (document.activeElement === this.lastFocusableElement) {
			e.preventDefault()
			this.firstFocusableElement.focus()
		}
	}

	/**
	 * Remove event listener
	 */
	destroy() {
		document.removeEventListener('keydown', this.setFocus)
	}
}
